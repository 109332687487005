import { Box, Button, Flex, Heading, Icon, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'

import ArrowIcon from 'assets/arrow.svg?component'
import Link from 'components/elements/Link'
import { MyService } from 'components/modules/Dashboard/MyServices/utils'

const styles = {
  service: {
    bgColor: 'white',
    minH: { base: '76px', md: '120px' },
    textDecoration: 'none',
    color: 'gray333',
    _hover: {
      textDecoration: 'none',
    },
  },
  serviceContent: {
    px: { base: 0, md: 12 },
    w: 'full',
    justifyContent: 'center',
  },
  serviceName: {
    fontSize: { base: '1.125rem', md: '1.5rem' },
    lineHeight: '1.66',
  },
  serviceDescription: {
    fontSize: { base: '0.875rem', md: '1.5rem' },
    lineHeight: '1.33',
    mt: '0 !important',
  },
  serviceIcon: {
    bgColor: { base: 'transparent', md: '#E2E2E2' },
    minW: { base: '76px', md: '120px' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    boxSize: { base: 8, lg: 10 },
    marginInlineEnd: 0,
    minW: 10,
    color: 'gray333',
  },
} as const satisfies SystemStyleInterpolation

const TAG = 'MyServiceCard'

const MyServiceCard: FunctionComponent<MyService> = ({ siteKey, icon, url, displayGetAccess }) => {
  const { t } = useTranslation()

  return (
    <Flex key={siteKey} {...(!displayGetAccess && { as: Link, href: url })} {...styles.service}>
      <Box {...styles.serviceIcon}>
        <Icon aria-hidden="true" as={icon} {...styles.icon} />
      </Box>

      <Stack spacing={2} {...styles.serviceContent}>
        <Heading as="h3" {...styles.serviceName}>
          {t(`pages.dashboard.myServices.services.${siteKey}.label`)}
        </Heading>
        <Text {...styles.serviceDescription}>{t(`pages.dashboard.myServices.services.${siteKey}.description`)}</Text>
      </Stack>

      <Flex height="full" alignItems="center" px={6}>
        {displayGetAccess ? (
          <Button variant="outline" as={Link} href={url}>
            {t('pages.dashboard.getAccess')}
          </Button>
        ) : (
          <Icon boxSize={{ base: 6, lg: 8 }} as={ArrowIcon} />
        )}
      </Flex>
    </Flex>
  )
}

MyServiceCard.displayName = TAG

export default MyServiceCard
