import { Flex, Heading, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'

import getCompanyName from 'commercetools/utils/getCompanyName'
import MyServiceCard from 'components/modules/Dashboard/MyServices/MyServiceCard'
import useNormetServicesLinks from 'components/modules/Dashboard/MyServices/utils'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const styles = {
  container: {
    py: { base: 0, md: 4 },
    pl: { base: 4, md: 6, lg: 10 },
    pr: { base: 4, lg: 6 },
    w: 'full',
    maxW: 'desktop',
    mt: 8,
    direction: 'column',
  },
  wrapper: {
    pl: 5,
    borderLeft: '5px solid',
    borderColor: '#EB2036',
  },
  heading: {
    fontSize: { base: '1.125rem', md: 'xl' },
    lineHeight: '1.75',
  },
  intro: {
    fontSize: { base: '0.875rem', md: 'lg' },
    lineHeight: 2,
    mt: '0 !important',
  },
  services: {
    width: 'full',
    mt: 10,
  },
} as const satisfies SystemStyleInterpolation

const TAG = 'MyServices'

const MyServices: FunctionComponent = () => {
  const { t } = useTranslation()
  const { current } = useBusinessUnit()
  const companyName = getCompanyName(current)
  const { availableServices } = useNormetServicesLinks()

  return (
    <Flex {...styles.container}>
      <Stack {...styles.wrapper}>
        <Heading as="h2" {...styles.heading}>
          {t('pages.dashboard.myServices.title')}
        </Heading>
        <Text {...styles.intro}>{t('pages.dashboard.myServices.description', { companyName })}</Text>
      </Stack>

      <Stack {...styles.services}>
        {availableServices.map((service) => (
          <MyServiceCard key={service.siteKey} {...service} />
        ))}
      </Stack>
    </Flex>
  )
}

MyServices.displayName = TAG

export default MyServices
