
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Container, SystemStyleInterpolation } from '@chakra-ui/react'
import getT from 'next-translate/getT'

import GreetingsBanner from 'components/modules/Dashboard/GreetingsBanner'
import MyServices from 'components/modules/Dashboard/MyServices'
import NewServices from 'components/modules/Dashboard/NewServices'
import getRevalidate from 'config/revalidate'
import withPageStaticProps from 'utils/next/withPageStaticProps'

interface StartPageProps {}

const TAG = 'Home'

const styles = {
  main: {
    h: 'full',
    w: 'full',
    maxW: 'full',
    p: 0,
    pt: 4,
    bgColor: 'gray.200',
  },
} as const satisfies SystemStyleInterpolation

const HERO_IMAGE_SRC = '/images/fleet-overview-hero-image.jpg'

const StartPage: NextPageWithLayout<StartPageProps> = () => (
  <Container data-testid={TAG} {...styles.main} centerContent>
    <GreetingsBanner />
    <MyServices />
    <NewServices />
  </Container>
)

export default StartPage

 const _getStaticProps = withPageStaticProps<StartPageProps>(async ({ locale }) => {
  if (locale === 'default' || !locale) {
    return { notFound: true }
  }

  const t = await getT(locale, 'common')
  const title = t('pages.fleet-overview.pageTitle')
  const description = t('pages.fleet-overview.pageDescription')

  return {
    props: {
      layout: {
        props: {
          seo: {
            title,
            description,
            image: { title, url: HERO_IMAGE_SRC, contentType: 'image/jpeg' },
          },
          showNavigation: false,
        },
      },
    },
    revalidate: getRevalidate(),
  }
})


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index.page',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  