const NORMET_ACADEMY_URL = process.env.NORMET_ACADEMY_URL

/**
 * Retrieves the Normet Academy URL from the environment variable `NORMET_ACADEMY_URL`.
 *
 * @returns {URL | undefined} The URL object if the environment variable is set and valid, otherwise `undefined`.
 *
 * @remarks
 * If the `NORMET_ACADEMY_URL` environment variable is not set, an error message will be logged and the function will return `undefined`.
 * If the `NORMET_ACADEMY_URL` is set but is not a valid URL, an error message will be logged and the function will return `undefined`.
 */
function getNormetAcademyURL(): URL | undefined {
  if (!NORMET_ACADEMY_URL) {
    console.error('Could not find URL. Please set environment variable NORMET_ACADEMY_URL.')
    return undefined
  }

  try {
    return new URL(NORMET_ACADEMY_URL)
  } catch (error) {
    console.error('Could not create a URL from NORMET_ACADEMY_URL:', error)
    return undefined
  }
}

export default getNormetAcademyURL
