import type { BusinessUnit } from '@commercetools/platform-sdk'

/**
 * Checks if the business unit aka company has access to the Academy
 */
export function hasBusinessUnitAccessToAcademy(businessUnit: DeepPartial<BusinessUnit> | undefined | null): boolean {
  return businessUnit?.custom?.fields?.ctAllowAcademyAccess ?? false
}

/**
 * Checks if the customer has access to the Academy
 */
export function hasCustomerAccessToAcademy(
  businessUnits: ReadonlyArray<DeepPartial<BusinessUnit> | undefined | null> | undefined | null,
): boolean {
  return !!businessUnits?.filter(Boolean)?.some(hasBusinessUnitAccessToAcademy)
}
