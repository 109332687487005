import { Flex, Heading, Text, SystemStyleInterpolation, Stack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'

import useNormetServicesLinks from 'components/modules/Dashboard/MyServices/utils'
import NewServiceCard from 'components/modules/Dashboard/NewServices/NewServiceCard'

const styles = {
  container: {
    py: { base: 0, md: 4 },
    pl: { base: 0, lg: 10 },
    pr: { base: 0, lg: 6 },
    w: 'full',
    maxW: 'desktop',
    mt: 8,
  },
  wrapper: {
    backgroundColor: 'white',
    px: { base: 0, md: 6, lg: 10 },
    pt: 12,
    pb: 14,
    w: 'full',
    borderTop: '13px solid #E2241B',
    direction: 'column',
  },
  heading: {
    px: { base: 4, md: 0 },
    fontSize: { base: '1.125rem', md: 'xl' },
    lineHeight: '1.75',
  },
  intro: {
    px: { base: 4, md: 0 },
    fontSize: { base: '0.875rem', md: 'lg' },
    lineHeight: 2,
    mt: '0 !important',
  },
} as const satisfies SystemStyleInterpolation

const TAG = 'NewServices'

const NewServices: FunctionComponent = () => {
  const { t } = useTranslation()

  const { newServices } = useNormetServicesLinks()

  return (
    <Flex {...styles.container}>
      <Flex {...styles.wrapper}>
        <Heading as="h2" {...styles.heading}>
          {t('pages.dashboard.newServices.title')}
        </Heading>
        <Text {...styles.intro}>{t('pages.dashboard.newServices.description')}</Text>
        <Stack spacing={5} mt={16}>
          {newServices.map((service, i) => (
            <NewServiceCard
              key={service.siteKey}
              {...service}
              backgroundColor={i % 2 === 0 ? 'secondary.beige.100' : '#EFEFEF'}
            />
          ))}
        </Stack>
      </Flex>
    </Flex>
  )
}

NewServices.displayName = TAG

export default NewServices
