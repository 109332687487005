import { Flex, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'

import getCompanyName from 'commercetools/utils/getCompanyName'
import { ChevronIcon } from 'components/elements/ChevronIcon'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const styles = {
  container: {
    w: 'full',
    maxW: 'full',
    bgColor: 'white',
    justifyContent: 'center',
  },
  header: {
    py: { base: 2, md: 4 },
    pl: { base: 4, md: 6, lg: 10 },
    pr: { base: 4, lg: 6 },
    w: 'full',
    maxW: 'desktop',
  },
  greetings: {
    color: 'gray333',
    fontSize: { base: '1.125rem', md: '1.25rem' },
    lineHeight: 2,
    fontWeight: '800',
  },
  companyName: {
    color: 'gray333',
    fontSize: { base: '0.875rem', md: '1rem' },
    lineHeight: 2,
    fontWeight: '400',
    textTransform: 'uppercase',
    m: '0 !important',
  },
} as const satisfies SystemStyleInterpolation

const TAG = 'DashboardBanner'

const DashboardBanner: FunctionComponent = () => {
  const { t } = useTranslation()
  const { current } = useBusinessUnit()

  const companyName = getCompanyName(current)

  const { customer } = useAuthState()
  const greetings = customer?.firstName
    ? t('pages.dashboard.welcome', { firstName: customer.firstName })
    : t('components.header.profile.welcome-to-normet')

  return (
    <Flex {...styles.container}>
      <Flex as="header" {...styles.header}>
        <Flex alignItems="center">
          <ChevronIcon boxSize={{ base: '40px', md: '60px' }} />
          <Stack>
            <Text {...styles.greetings}>{greetings}</Text>
            <Text {...styles.companyName}>{companyName}</Text>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  )
}

DashboardBanner.displayName = TAG

export default DashboardBanner
