import { useMemo } from 'react'

import NormetAcademyIcon from 'assets/normetAcademy.svg?component'
import NormetAnalyticsIcon from 'assets/normetAnalytics.svg?component'
import NormetPortalIcon from 'assets/normetPortal.svg?component'
import NormetStoreIcon from 'assets/normetStore.svg?component'
import { RoutePaths } from 'config/routes'
import getNormetAcademyURL from 'utils/getNormetAcademyURL'
import getNormetAnalyticsPortalURL from 'utils/getNormetAnalyticsPortalURL'
import getNormetLandingURL from 'utils/getNormetLandingURL'
import getNormetStoreURL from 'utils/getNormetStoreURL'
import { hasBusinessUnitAccessToAcademy } from 'utils/hasAccessToAcademy'
import { hasBusinessUnitAccessToAnalyticsPortal } from 'utils/hasAccessToAnalyticsPortal'
import { hasBusinessUnitAccessToStore } from 'utils/hasAccessToStore'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

type MyServiceKey = TranslationKeys['common'] extends infer LINK
  ? LINK extends `pages.dashboard.myServices.services.${infer KEY}.${string}`
    ? KEY
    : never
  : never

type NewServiceKey = TranslationKeys['common'] extends infer LINK
  ? LINK extends `pages.dashboard.newServices.services.${infer KEY}.${string}`
    ? KEY
    : never
  : never

export interface MyService {
  siteKey: MyServiceKey
  url: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  displayGetAccess?: boolean
}

export interface NewService {
  siteKey: NewServiceKey
  url: string
}

function useNormetServicesLinks() {
  const { current } = useBusinessUnit()

  const hasAccessToStore = hasBusinessUnitAccessToStore(current)
  const normetStoreURL =
    (hasAccessToStore ? getNormetStoreURL() : process.env.NORMET_NO_ACCESS_TO_STORE_URL)?.toString() || ''

  const hasAccessToAnalyticsPortal = hasBusinessUnitAccessToAnalyticsPortal(current)
  const analyticsPortalURL = getNormetAnalyticsPortalURL(hasAccessToAnalyticsPortal)?.toString() || ''

  const hasAccessToAcademy = hasBusinessUnitAccessToAcademy(current)
  const academyUrl =
    (hasAccessToAcademy ? getNormetAcademyURL() : process.env.NORMET_NO_ACCESS_TO_ACADEMY_URL)?.toString() || ''

  return useMemo(() => {
    const availableServices: Array<MyService> = [
      {
        siteKey: 'portal',
        icon: NormetPortalIcon,
        url: RoutePaths.equipmentOverview,
      },
      {
        siteKey: 'store',
        icon: NormetStoreIcon,
        url: normetStoreURL,
        displayGetAccess: !hasAccessToStore,
      },
    ]

    const newServices: Array<NewService> = []

    const analytics = { siteKey: 'analytics', url: analyticsPortalURL }
    if (hasAccessToAnalyticsPortal) {
      availableServices.push({
        ...(analytics as MyService),
        icon: NormetAnalyticsIcon,
      })
    } else {
      newServices.push(analytics as NewService)
    }

    const academy = { siteKey: 'academy', url: academyUrl }
    if (hasAccessToAcademy) {
      availableServices.push({
        ...(academy as MyService),
        icon: NormetAcademyIcon,
      })
    } else {
      newServices.push(academy as NewService)
    }

    newServices.push({
      siteKey: 'landing',
      url: getNormetLandingURL()?.toString() || '',
    })

    return {
      availableServices,
      newServices,
    }
  }, [normetStoreURL, hasAccessToStore, analyticsPortalURL, hasAccessToAnalyticsPortal, academyUrl, hasAccessToAcademy])
}

export default useNormetServicesLinks
